/* eslint-disable no-unused-expressions */
/* eslint-disable func-names */
/* global Visitor, TrackStar, ECS_LIB_BUILD_VERSION */
/* eslint no-underscore-dangle: ["error", { "allow": ["_newWebAnalytics", "_oldWebAnalytics", "_turbotax"] }] */
/* multiple of above linter issues  */

import enumEnv from './constants/env';
import config from './constants/config';
import { log, LOG_TYPE } from './utils/logger';
import { loadScriptPromise, highEntropyValuesAvailable } from './utils/browserUtils';
import { marketingCloudOrgId } from './constants/trackstarConstants';

const file = 'bootstrapWebAnalytics.js';

/**
 *
 * @param {string} writeKey write key string
 * @param {string} env environment
 * @param {string} loadAdobeVisitorApi whether enable adobe visitor api
 * @param {string} bootstrapEvent bootstrap event to trigger library
 * @param {string} loadModules an Opt-in module to load performance metrics
 * @param {string} nosequencingofcalls tracking-core need not perform call sequencing
 * @param {string} nowait tracking-core need not wait for data such as Adobe MCID
 * @param {string} linkwait number of milliseconds wait to navigate to anchor tags
 * @param {string} adobeEdgeConfigId Adobe Id for the Web SDK calls - related to the data stream
 * @returns {promise} bootstrapPromise added for unit tests to wait for the completion of the function before checking assertions
 */
export const bootstrapWebAnalytics = (
  writeKey,
  env,
  loadAdobeVisitorApi,
  bootstrapEvent,
  loadModules,
  nosequencingofcalls,
  nowait,
  linkwait,
  adobeEdgeConfigId
) => {
  const bootstrapPromise = new Promise((resolveIt) => {
    const hostDomain = config[env].ECS_LIB_HOST_DOMAIN;
    const trackLibEntryName = 'track-event-lib';
    const trackLibFileName = `${trackLibEntryName}.min.js`;
    let trackLibPath = '';
    const initLibPath = document.querySelector('script[src*=track-event-lib-init]')
      ? document.querySelector('script[src*=track-event-lib-init]').src.slice(0, -28)
      : '';
    const versionedLibPath =
      window.intuit.tracking.ecs.ownDomain && initLibPath.length > 0
        ? initLibPath
        : `${hostDomain}/analytics/${ECS_LIB_BUILD_VERSION}`;
    const loadModulesList = loadModules ? loadModules.split(',') : [];
    const loadModulesPrefix = 'track-event-lib-';
    const loadModulesObj = {
      prod: `${versionedLibPath}/${loadModulesPrefix}`,
      e2e: `${versionedLibPath}/${loadModulesPrefix}`,
      dev: loadModulesPrefix,
      test: loadModulesPrefix
    };

    const adobeAlloySrc =
      env === 'prod'
        ? 'https://lib.intuitcdn.net/libs/adobe/AEP/alloy.2230.min.js'
        : 'https://preprod-lib.intuitcdn.net/libs/adobe/AEP/alloy.2230.min.js';

    const aepContext = highEntropyValuesAvailable() || [];

    if (
      enumEnv.DEV.toLowerCase() === env.toLowerCase() ||
      enumEnv.TEST.toLowerCase() === env.toLowerCase()
    ) {
      trackLibPath = trackLibFileName;
    } else {
      trackLibPath =
        window.intuit.tracking.ecs.ownDomain && initLibPath.length > 0
          ? `${initLibPath}/${trackLibFileName}`
          : `${versionedLibPath}/${trackLibFileName}`;
    }

    /**
     * Take a beacon and load library
     * @param {string} e a beacon event
     */
    const initBeaconing = () => {
      const loggingMethod = 'initBeaconing';
      // eslint-disable-next-line no-restricted-globals
      addEventListener('unhandledrejection', (event) => {
        // log alloy errors
        log(LOG_TYPE.WARN, {
          fileName: 'bootstrapWebAnalytcs.js',
          methodName: 'initBeaconing',
          trackingApplication: 'trackstar',
          msg: `Unhandled promise rejection - reason: ${event.reason}`
        });
      });
      if (window.intuit.tracking.ecs.initBeaconed) {
        return; // init has already been run
      }
      window.intuit.tracking.ecs.initBeaconed = true;

      const initBeaconingStartTime = performance.now();
      const promises = [];

      promises.push(loadScriptPromise(trackLibPath, 3));
      loadModulesList.forEach((module) => {
        promises.push(loadScriptPromise(`${loadModulesObj[env.toLowerCase()]}${module}.min.js`, 3));
      });

      if (loadAdobeVisitorApi === 'true') {
        let visitorAPIpath = 'visitorapi.min.js';
        if (
          !(
            enumEnv.DEV.toLowerCase() === env.toLowerCase() ||
            enumEnv.TEST.toLowerCase() === env.toLowerCase()
          )
        ) {
          visitorAPIpath = `${versionedLibPath}/${visitorAPIpath}`;
        }
        // visitorapi.min.js gets blocked more often than the trackstar libraries (other blocklists)
        // We do not want to miss the tracking just because visitorapi was not loaded
        // remove it from one of the promises to wait before tracking
        // promises.push(
        loadScriptPromise(visitorAPIpath, 3).then(() => {
          Visitor.getInstance('969430F0543F253D0A4C98C6@AdobeOrg', {
            trackingServer: 'ci.intuit.com',
            trackingServerSecure: 'sci.intuit.com',
            marketingCloudServer: 'ci.intuit.com',
            marketingCloudServerSecure: 'sci.intuit.com',
            disableThirdPartyCalls:
              window.intuit.tracking.ecs.adobeDisableThirdPartyCalls === 'true'
          });
        });
        // );
      }

      if (adobeEdgeConfigId) {
        // Adobe Alloy Web SDK script
        // eslint-disable-next-line require-jsdoc
        !(function (n, o) {
          //  eslint-disable-next-line no-shadow
          o.forEach(function (o) {
            n[o] ||
              // eslint-disable-next-line no-param-reassign, no-underscore-dangle
              ((n.__alloyNS = n.__alloyNS || []).push(o),
              // eslint-disable-next-line no-param-reassign
              (n[o] = function () {
                // eslint-disable-next-line prefer-rest-params
                const u = arguments;
                return new Promise(function (i, l) {
                  n[o].q.push([i, l, u]);
                });
              }),
              // eslint-disable-next-line no-param-reassign
              (n[o].q = []));
          });
        })(window, ['alloy']);
        promises.push(loadScriptPromise(adobeAlloySrc, 3));
      }

      if (
        enumEnv.TEST.toLowerCase() === env.toLowerCase() ||
        window.location.search.indexOf('trackingTest=true') > -1
      ) {
        // Introducing a delay so integration test can set up the interceptor before the page call happens in the consumer pages
        promises.push(
          new Promise((resolve) => {
            setTimeout(
              () => resolve(),
              window.location.hostname === 'localhost.intuit.com' ? 2000 : 25000
            );
          })
        );
      }

      Promise.all(promises)
        .then(() => {
          if ((adobeEdgeConfigId && !window.alloy) || adobeEdgeConfigId === 'test') {
            if (env.toLowerCase() !== enumEnv.PROD.toLowerCase()) {
              window.dispatchEvent(new Event('unhandledrejection'));
            }
            log(LOG_TYPE.ERROR, {
              fileName: 'bootstrapWebAnalytcs.js',
              methodName: 'initBeaconing',
              trackingApplication: 'trackstar',
              msg: `Adobe Alloy configure not called since window.alloy not set in ${document.location.href}`
            });
          }
          // eslint-disable-next-line require-jsdoc
          return new Promise((resolve) => {
            if (window.alloy) {
              window
                .alloy('configure', {
                  edgeConfigId: adobeEdgeConfigId,
                  orgId: marketingCloudOrgId,
                  edgeDomain: 'aec.intuit.com',
                  clickCollectionEnabled: false,
                  idMigrationEnabled: true,
                  context: aepContext,
                  onBeforeEventSend: (data) => {
                    if (
                      window.intuit?.tracking?.ecs?.enableAep === 'true' &&
                      !data.xdm?._turbotax
                    ) {
                      return false;
                    }
                    return true;
                  }
                })
                .then(() => {
                  window.intuit.tracking.ecs.alloy = window.alloy;
                  window.intuitAlloy = window.alloy;
                  resolve('in then');
                })
                .catch((err) => {
                  log(LOG_TYPE.ERROR, {
                    fileName: 'bootstrapWebAnalytcs.js',
                    methodName: 'initBeaconing',
                    trackingApplication: 'trackstar',
                    msg: `exception in Adobe Alloy configure call: ${err && err.toString()}`
                  });
                  resolve('in catch');
                });
            } else {
              resolve('no alloy');
            }
          });
        })
        .then(() => {
          return TrackStar[trackLibEntryName].WebAnalytics.initializeAnalyticsJS(writeKey);
        })
        .then((ajsInstance) => {
          log(
            LOG_TYPE.INFO,
            'bootstrapWebAnalytics.js',
            'initBeaconing',
            `initBeaconing totalTime: ${performance.now() - initBeaconingStartTime} ms`
          );
          let _oldWebAnalytics = window.intuit.tracking.ecs.webAnalytics;
          const { initConfig } = _oldWebAnalytics;
          if (initConfig) {
            window.intuit.tracking.ecs.analytics = ajsInstance;

            initConfig.write_key = writeKey;
            initConfig.env = env;
            initConfig.loadAdobeVisitorApi = loadAdobeVisitorApi === 'true';
            initConfig.nosequencingofcalls = nosequencingofcalls;
            initConfig.nowait = nowait;
            initConfig.linkwait = linkwait;

            const _newWebAnalytics = new TrackStar[trackLibEntryName].WebAnalytics(initConfig);
            // set constants on new WebAnalytics
            _newWebAnalytics.constants = _oldWebAnalytics.constants;
            _newWebAnalytics.invoked = _oldWebAnalytics.invoked;
            // Read from queue and make actual calls.
            while (_oldWebAnalytics.q && _oldWebAnalytics.q.length > 0) {
              const args = _oldWebAnalytics.q.shift();
              const method = args.shift();
              if (typeof _newWebAnalytics[method] === 'function') {
                const segmentMethod = _newWebAnalytics[method];
                segmentMethod.apply(_newWebAnalytics, args);
              }
            }
            _oldWebAnalytics = null;
            window.intuit.tracking.ecs.webAnalytics = _newWebAnalytics;
          } else {
            log(LOG_TYPE.ERROR, file, loggingMethod, 'initConfig is missing!');
          }
        })
        .catch((error) => {
          log(
            LOG_TYPE.ERROR,
            file,
            loggingMethod,
            `Error replaying queued events: ${error && error.message}`,
            '',
            false,
            'replay-queued'
          );
        })
        .finally(() => {
          resolveIt();
        });
    };

    // or run init automatically for standard use cases
    if (bootstrapEvent) {
      document.addEventListener(bootstrapEvent, initBeaconing);
    } else if (window.intuit.tracking.ecs.isBrowserPolyfilled) {
      document.addEventListener('BootstrapWebAnalytics', initBeaconing);
    } else {
      document.addEventListener('DOMContentLoaded', initBeaconing);
    }
  });
  return bootstrapPromise;
};
export default bootstrapWebAnalytics;
